<!-- eslint-disable @shein-aidc/cccx/notUseImgTag-->
<template>
  <s-drawer
    :append-to-body="true"
    animate-type="half"
    max-size="80%"
    no-header
    custom-class="cccStoreBanner-drawer"
    :visible.sync="localVisible"
  >
    <div
      class="close-icon"
      @click="closeDrawer"
    >
      <i class="suiiconfont sui_icon_close_18px"></i>
    </div>
    <div class="cccStoreBanner-drawer-content">
      <div lass="cccStoreBanner-drawer-content_header">
        <!-- 普通店铺的背景图 -->
        <section
          v-if="!isShowBranchShop && shopData.storeBG"
          class="cccStoreBanner-drawer__banner-bg"
          :style="{
            'backgroundImage': `url(${shopData.storeBG})`
          }"
        ></section>
        <section
          class="shop-drawer__header"
          :class="[isShowBranchShop ? 'shop-drawer__header-branch' : 'shop-drawer__header-ordinary']"
        >
          <div
            v-if="shopData.logo"
            class="shop-drawer__header-logo"
          >
            <img
              :src="shopData.logo.replace(/^https?:/, '')"
              aria-hidden="true"
            />
          </div>
          <div class="shop-drawer__header-detail">
            <!-- 店铺名 -->
            <div class="shop-drawer__header-detail-top">
              <div
                v-if="shopData.title"
                class="shop-drawer__header-detail-title"
              >
                <span class="text-ellipsis">{{ shopData.title || '' }}</span>
              </div>
            </div>
            <div
              class="tag_wrap"
            >
              <!-- trends label -->
              <div
                v-if="!!trendsLabelInfo.status"
                class="shop-banner__trends-label"
              >
                <img
                  v-if="trendsLabelInfo.trendsLabelImg"
                  :src="trendsLabelInfo.trendsLabelImg"
                />
                <div
                  v-else-if="trendsLabelInfo.trendsLabelText"
                  class="trends-label__text"
                >
                  {{ trendsLabelInfo.trendsLabelText }}
                </div>
              </div>
              <!-- brand label -->
              <div
                v-if="isShowBranchShop"
                class="shop-drawer__header-detail-tagWrap"
              >
                <img
                  :src="`${PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`"
                  class="branchIcon"
                />
                <div>{{ storeBannerLang.branchShopTag }}</div>
              </div>
              <!-- 优选卖家 label -->
              <S-Label
                v-if="isPreferredSeller"
                type="sellpoint"
                class="shop-banner__label"
              >
                <div class="shop-drawer__header-detail-seller">
                  <img
                    :src="`${PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`"
                  />
                  <span> {{ storeBannerLang.bestSaleTag }}</span>
                </div>
              </S-Label>
              <!--本地卖家-->
              <S-Label
                v-if="formattedData?.localSellerLabel"
                type="success"
                class="shop-banner__label"
              >
                <span>{{ formattedData.localSellerLabel }}</span>
              </S-Label>
            </div>
          </div>
        </section>
      </div>
      <div class="cccStoreBanner-drawer-content_body">
        <div
          v-if="shortBannerDetailInfo.length > 1"
          class="short-banner-drawer__detail-info"
        >
          <div
            v-for="item in shortBannerDetailInfo"
            :key="item.name"
            class="short-banner-drawer__info-item"
          >
            <h3 class="short-banner-drawer__info-num">
              {{ item.num }}
            </h3>
            <p class="short-banner-drawer__info-name">
              {{ item.name }}
            </p>
          </div>
        </div>
        <div
          v-if="storeBannerLabel.length > 0"
          class="shop-drawer-labels-container"
        >
          <div
            v-for="labelItem in storeBannerLabel"
            :key="labelItem.type"
            class="shop-drawer-labels-item"
          >
            <div class="shop-drawer-labels-item_title">
              <img
                :src="`${PUBLIC_CDN}/pwa_dist/images/ccc/shop_banner_labels_pop/${labelItem.type}.png`"
                class="shop-drawer-labels-item__img"
              />
              {{ labelItem.title }}
            </div>
            <div
              v-if="labelItem.desc"
              class="shop-drawer-labels-item_desc"
            >
              {{ labelItem.desc }}
            </div>
            <div
              v-if="labelItem.url"
              class="shop-drawer-labels-item_view-more"
              @click="showViewMore(labelItem.url)"
            >
              {{ labelItem.viewMore }}
              <i class="suiiconfont sui_icon_more_right_16px"></i>
            </div>
          </div>
        </div>
        <div
          v-if="isShowDescriptions && storeBannerLabel.length > 0"
          class="shop-drawer-content-line-gap"
        ></div>
        <div 
          v-if="isShowDescriptions"
          class="shop-drawer-descriptions-container"
        >
          <div class="shop-drawer__title">
            <img
              src="https://img.ltwebstatic.com/images3_ccc/2024/06/17/57/1718614421baf56c26901f42766667cefb3b709714.png"
              class="shop-drawer-descriptions__img"
            />
            {{ storeBannerLang.descTitle }}
          </div>
          <div class="shop-drawer__content">
            {{ shopData.descriptions || '' }}
          </div>
        </div>
      </div>
    </div>
  </s-drawer>
</template>
<script>
import { Drawer as SDrawer, Label as SLabel } from '@shein/sui-mobile'
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  components: {
    SDrawer,
    SLabel,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shopData: {
      type: Object,
      default() {
        return {}
      }
    },
    storeBannerLang: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formattedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PUBLIC_CDN,
      shortBannerDetailInfo: [],
    }
  },
  computed: {
    // 店招是否命中新样式（品牌店&&命中实验）
    isShowBranchShop() {
      return this.shopData?.storeBusinessType === 2
    },
    isPreferredSeller() {
      return this.formattedData?.isPreferredSeller
    },
    storeBannerLabel() {
      return this.formattedData?.bannerLabel || []
    },
    trendsLabelInfo(){
      return this.shopData.trendsLabelInfo || {}
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(v) {
        this.$emit('update:visible', v)
      }
    },
    isShowDescriptions(){
      return !!this.formattedData?.isShowDescriptions
    }
  },
  mounted() {
    this.initShortBannerDetailInfo()
  },
  methods: {
    closeDrawer() {
      this.localVisible = false
    },
    showViewMore(url) {
      window.location.href = url
    },
    initShortBannerDetailInfo() {
      const { shopData, formattedData } = this
      const { ratingLang, itemsLang, followsCountLang } = this.storeBannerLang
      const { followsCount } = shopData
      const { storeScores, storeGoodsQuantity } = formattedData ?? {}
      if (storeScores) {
        this.shortBannerDetailInfo.push({
          name: ratingLang,
          num: storeScores,
        })
      }
      if (storeGoodsQuantity && storeGoodsQuantity !== '0') {
        this.shortBannerDetailInfo.push({
          name: itemsLang,
          num: storeGoodsQuantity,
        })
      }
      if (followsCount && followsCount !== '0') {
        this.shortBannerDetailInfo.push({
          name: followsCountLang,
          num: followsCount,
        })
      }
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern  */
.cccStoreBanner-drawer {
  box-sizing: border-box;
  min-height: 436/75rem;
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */

  .S-drawer__body {
    width: 100%;
    font-family: Helvetica,Arial,sans-serif;
    font-style: normal;
    font-size: 24/75rem;
    color: #000;
    line-height: 32/75rem;
    box-sizing: border-box;
    overflow: visible !important; /* stylelint-disable-line declaration-no-important */

    .close-icon {
      padding: 6px 6px 10px 10px;
      position: absolute;
      transform: scale(0.7);
      transform-origin: top right;
      top: 0;
      right: 0;
      z-index: 10; /* stylelint-disable-line  */
      i {
        color: #000;
      }
    }

    >p {
      width: 100%;
      width: 100%;
      word-wrap: break-word;
      white-space: normal;
    }
  }
  .shop-banner__label {
    box-sizing: border-box;
    height: 32/75rem;
    padding: 4/70rem 8/70rem;
    font-size: 20/75rem;
    line-height: 24/75rem;
    margin-right: 4px;
    margin-bottom: 4px;
    vertical-align: middle;
    white-space: nowrap;
    span{
      font-size: 20/75rem;
      line-height: 24/75rem;
    }
  }

  &__banner-bg {  /* stylelint-disable-line  */
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background-size: cover;
    background-position: center;
    filter: opacity(0.1);
    &::after {  /* stylelint-disable-line  */
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32.03%, #FFFFFF 94.16%);
    }
  }
  .cccStoreBanner-drawer-content{
    display: flex;
    flex-direction: column;
    max-height: 80vh;
  }
  .cccStoreBanner-drawer-content_header{
    position: relative;
    flex-shrink: 0;
  }
  .cccStoreBanner-drawer-content_body{
    overflow-y: auto;
    padding:12px 0 24px;
  }
}

.shop-drawer {
  position: relative;
  height: 100%;
  padding: 32/75rem 0 22/75rem;

  &__header {
    .flexbox();
    flex-direction: row;
    position: relative;
    padding: 20/75rem 30/75rem 0;
  }

  &__header-branch {
    background: linear-gradient(180deg, #E2EDF3 0.34%, #FFFFFF 100%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);

  }

  &__header-logo {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.02);
    border-radius: 4/75rem;
    width: 128/75rem;
    height: 128/75rem;
    margin-right: 32/75rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-top: -44/75rem;
    background-color: #fff;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__header-detail {
    .flexbox();
    flex: 1;
    font-family: Helvetica,Arial,sans-serif;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
  }

  &__header-detail-top {
    .flexbox();
    align-items: center;
    flex-wrap: wrap;
    line-height: 40/75rem;
  }

  &__header-detail-title {
    .font-dpr(32px);
    font-weight: 700;
    text-transform: uppercase;
    color: #333;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
  }

  &__header-detail-tagWrap {
    .flexbox();
    .border-dpr(border, 0.5px, #BCC8D3);
    .font-dpr(20px);
    margin-right: 4px;
    margin-bottom: 4px;
    height: 32/75rem;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(128.64deg, #E2EDF3 7.72%, #F0F8FF 34.23%, #DAE7F6 98.52%);
    border-radius: 4/75rem;
    color: #2D3540;
    padding: 3/75rem 4/75rem;
    align-items: center;
    font-weight: 500;

    >div {
      height: 24/75rem;
      line-height: 24/75rem;
    }
    .branchIcon {
      width: 24/75rem;
      margin-right: 2/75rem;
    }
  }
  &__header-detail-seller {
    white-space: nowrap;
    img {
      width: 24/70rem;
      // margin-right: 2px;
      vertical-align: top;
    }
  }


}

.short-banner-drawer {
  &__detail-info {
    display: flex;
    align-items: stretch;
    padding: 12/37.5rem 0;
    border-radius: 4/37.5rem;
    border: 1px solid #E5E5E5;
    background-color: #F6F6F6;
    margin: 4/37.5rem 16/37.5rem;
  }
  &__info-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    &:not(:first-child) {
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 20/37.5rem;
        background-color: #E5E5E5;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__info-num {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1875;
    font-family: 'SF-UI-Text-Bold';
    color: #000;
  }
  &__info-name {
    font-size: 12px;
    line-height: 1.166667;
    font-family: 'SF-UI-Text-Regular';
    color: #767676;
    margin-top: 4/37.5rem;
  }
}

.shop-drawer-labels-container{
    padding: .32rem /* 12/37.5 */ .426667rem /* 16/37.5 */  .426667rem;
    .shop-drawer-labels-item{
      &:not(:first-child){
        margin-top: .426667rem /* 16/37.5 */;
      }
    }
    .shop-drawer-labels-item_title{
      color: var(---sui_color_gray_dark1, #000);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      line-height: .426667rem;
    }
    .shop-drawer-labels-item__img{
      width: .426667rem /* 16/37.5 */;
      height: .426667rem /* 16/37.5 */;
      object-fit: cover;
      margin-right: .213333rem /* 8/37.5 */;
    }
    .shop-drawer-labels-item_desc{
      margin-top: .266667rem /* 10/37.5 */;
      color: var(---sui_color_gray_dark2, #666);
      font-size: 12px;
      line-height: 130%;
    }

    .shop-drawer-labels-item_view-more {
      display: flex;
      align-items: center;
      margin-top: .266667rem /* 10/37.5 */;
      color: var(---sui_color_primary, #2d68a7);
      font-size: 12px;
      line-height: 130%;
    }
   
  }
  .shop-drawer-content-line-gap{
      background: #F6F6F6;
      height: 10px;
  }
  .shop-drawer-descriptions-container{
    padding: 16px 16px;

    .shop-drawer__title{
      color: var(---sui_color_gray_dark1, #000);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .shop-drawer-descriptions__img{
      width: .426667rem /* 16/37.5 */;
      height: .426667rem /* 16/37.5 */;
      object-fit: cover;
      margin-right: .213333rem /* 8/37.5 */;
    }
    .shop-drawer__content{
      margin-top: 12px;
      color: var(---sui_color_gray_dark2, #666);
      font-size: 12px;
      line-height: 130%; /* 15.6px */
    }
  }
  .shop-banner__trends-label{
   margin: 0 4px 4px 0;
   height: 0.42666667rem;
   overflow: hidden;
   &>img{
    display: block;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
   }
   .trends-label__text{
    padding: 0 .133333rem /* 5/37.5 */;
    line-height: 0.42666667rem;
    color: var(---sui_color_white, #FFF);
    font-size: .293333rem /* 11/37.5 */;
    font-weight: 600;
   }
}
</style>
<style lang="less" scoped>
.tag_wrap{
  overflow: hidden; 
  max-height: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

</style>
