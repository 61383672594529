<template>
  <div
    ref="labelWrapper"
    class="short-banner__data-labels-wrapper"
    :class="labelResolveStatus === ResolveStatus.UNSOLVE ? 'unsolve' : 'resolved'"
  >
    <div
      ref="labelContent"
      class="short-banner__data-labels"
    >
      <component
        :is="label.name"
        v-for="(label, index) in renderLabels"
        :key="index"
        size="small"
        v-bind="label.props"
      />
    </div>
  </div>
</template>

<script>
import TrendLabel from './data-labels/TrendLabel.vue'
import BrandLabel from './data-labels/BrandLabel.vue'
import PreferredSellerLabel from './data-labels/PreferredSellerLabel.vue'
import LocalSellerLabel from './data-labels/LocalSellerLabel.vue'

export default {
  components: {
    TrendLabel,
    BrandLabel,
    PreferredSellerLabel,
    LocalSellerLabel,
  },
}
</script>

<script setup name="ShortBannerDataLabels">
import { ref, computed, onMounted, nextTick } from 'vue'

const props = defineProps({
  constantsData: {
    type: Object,
    default: () => ({}),
  },
  storeBannerLang: {
    type: Object,
    default: () => ({}),
  },
  shopData: {
    type: Object,
    default: () => ({}),
  },
  formattedData: {
    type: Object,
    default: () => ({}),
  },
})

const ResolveStatus = {
  UNSOLVE: 1,
  RESOLVED: 2,
}

const labelWrapper = ref(null)
const labelContent = ref(null)
const labelResolveStatus = ref(ResolveStatus.UNSOLVE)
const trendsLabelInfo = computed(() => props.shopData?.trendsLabelInfo || {})
const allLabels = [
  {
    show: () => !!trendsLabelInfo.value.status,
    component: 'TrendLabel',
    getProps: () => ({
      trendsLabelInfo: trendsLabelInfo.value,
    }),
  }, 
  {
    show: () => !!props.shopData?.isBrandStore,
    component: 'BrandLabel',
    getProps: () => ({
      constantsData: props.constantsData,
      storeBannerLang: props.storeBannerLang,
    }),
  }, 
  {
    show: () => !!props.formattedData?.isPreferredSeller,
    component: 'PreferredSellerLabel',
    getProps: () => ({
      constantsData: props.constantsData,
      storeBannerLang: props.storeBannerLang,
    }),
  }, 
  {
    show: () => !!props.formattedData?.localSellerLabel,
    component: 'LocalSellerLabel',
    getProps: () => ({
      labelText: props.formattedData.localSellerLabel,
    }),
  }
]
const renderLabels = ref([])
const tryAddLabel = (remainLabels, containerWidth) => {
  if (containerWidth <= 0 || !remainLabels?.length) {
    labelResolveStatus.value = ResolveStatus.RESOLVED
    return
  }
  const label = remainLabels.shift()
  renderLabels.value.push({
    name: label.component,
    props: label.getProps(),
  })
  nextTick(() => {
    const labelContentWidth = labelContent.value.clientWidth
    if (containerWidth > labelContentWidth) {
      tryAddLabel(remainLabels, containerWidth)
    } else {
      renderLabels.value.pop()
      labelResolveStatus.value = ResolveStatus.RESOLVED
    }
  })
}
onMounted(() => {
  const showLabels = allLabels.filter(label => label.show())
  const remainWidth = labelWrapper.value?.clientWidth
  tryAddLabel(showLabels, remainWidth)
})
</script>

<style lang="less" scoped>
.short-banner {
  &__data-labels-wrapper {
    &.unsolve {
      flex: 1;
      .short-banner__data-labels {
        display: inline-block;
        white-space: nowrap;
      }
      .shop-banner__trends-label,
      .shop-banner__label {
        display: inline-block;
      }
    }
    &.resolved {
      display: flex;
      align-items: center;
      .short-banner__data-labels {
        display: flex;
        align-items: center;
        & > :not(:first-child) {
          margin-left: 4/37.5rem;
        }
      }
    }
  }
}
</style>

