<template>
  <div>
    <ShortShopBanner 
      v-if="showShortShopBanner"
      v-bind="props"
      :formatted-data="formattedData"
      @show-description-drawer="showDescriptionDrawer"
    />
    <NormalShopBanner
      v-else
      v-bind="props"
      :formatted-data="formattedData"
      @show-description-drawer="showDescriptionDrawer"
    />
    <!-- 点击描述弹窗 -->
    <LazyMount>
      <DescriptionDrawer
        :visible.sync="isDescriptionDrawerVisible"
        :shop-data="shopData"
        :store-banner-lang="storeBannerLang"
        :formatted-data="formattedData"
      />
    </LazyMount>
  </div>
</template>

<script setup name="ShopBanner">
import { ref, computed } from 'vue'
import { LazyMount } from '@shein/sui-mobile'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props'
import NormalShopBanner from './NormalShopBanner.vue'
import ShortShopBanner from './ShortShopBanner.vue'
import DescriptionDrawer from './DescriptionDrawer.vue'

const props = defineProps(commonProps)
const isDescriptionDrawerVisible = ref(false)
const shopData = computed(() => props.propData?.storeBanner)
const storeBannerLang = computed(() => props.context?.language?.storeBanner || {})
const showShortShopBanner = computed(() => props.context?.storeAbtResult?.shortstoreboard?.p?.shortstoreboard === 'B' && shopData.value.isTopTabStore)
const isCCCshopBanner = computed(() => props.context?.storeAbtResult?.StorePerformanceOptimization2?.p?.StorePerformanceOptimization2 === 'A')

const showDescriptionDrawer = () => {
  isDescriptionDrawerVisible.value = true
}
const getLocalSellerLabelText = (shopData) => {
  const { storeType, salesArea, siteUid, isBrandStore } = shopData || {}
  const showLocalSellerLabel = storeType === 1 && !isBrandStore
  if(!showLocalSellerLabel) return ''
  let saleText = ''
  if(salesArea == 1) {
    saleText = siteUid == 'pwus' ? (storeBannerLang.value?.usSaleTag || '') : (storeBannerLang.value?.localSaleTag || '')
  } else if(salesArea == 2) {
    saleText = siteUid == 'pwus' ? (storeBannerLang.value?.usSaleTag || '') : (storeBannerLang.value?.saleTag || '')
  }
  return saleText
}
const getStoreBannerLabel = (shopData) => {
  const bannerLabel = []
  const { storeServicePolicyLabel = [] } = shopData || {}
  const showServicePolicyLabel = showShortShopBanner.value && storeServicePolicyLabel.length > 0
  if(showServicePolicyLabel) {
    bannerLabel.push(...storeServicePolicyLabel)
  }
  return {
    bannerLabel,
    showServicePolicyLabel,
  }
}

const getStoreScores = (shopData) => {
  const { score } = shopData ?? {}
  if (!+score) return ''
  return (+score).toFixed(2) 
}

const getStoreGoodsQuantity = (shopData) => {
  const { quantity } = shopData ?? {}
  const num = parseInt(quantity)
  if (!num) return '0'
  if (num <= 1000) {
    return String(num)
  }else if (num < 10000) {
    return parseInt(num / 100) / 10 + 'K'
  }else if (num <= 99000) {
    return parseInt(num / 1000) + 'K'
  }else {
    return '99K+'
  }
}

const getCccAnalysisInfo = (shopData) => {
  return {
    hrefTarget: shopData?.score ? shopData.storeCode : 0,
    hrefType: shopData?.score ? 'storeReviews' : 'noJump',
  }
}
const formattedData = computed(() => {
  const shopdata = shopData.value
  const { labels = [], descriptions, isBrandStore } = shopdata
  const isPreferredSeller = labels.some(label => label.labelCode === 'preferredSeller')
  const localSellerLabel = getLocalSellerLabelText(shopdata)
  const hasDataLabels = !!shopdata?.trendsLabelInfo?.status || shopdata.isBrandStore || isPreferredSeller || !!localSellerLabel
  const bannerLabelInfo = getStoreBannerLabel(shopdata)
  const storeScores = getStoreScores(shopdata)
  const storeGoodsQuantity = getStoreGoodsQuantity(shopdata)
  const cccAnalysisInfo = getCccAnalysisInfo(shopdata)
  const isShowDescriptions = descriptions && (isBrandStore || labels.some(label => label.labelCode === 'Show_Description'))
  return {
    showShortShopBanner: showShortShopBanner.value,
    localSellerLabel,
    isPreferredSeller,
    ...bannerLabelInfo,
    hasDataLabels,
    storeScores,
    storeGoodsQuantity,
    cccAnalysisInfo,
    isCCCshopBanner: isCCCshopBanner.value,
    isShowDescriptions,
  }
})
</script>
