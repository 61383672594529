var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.className, 'shop-entry__storeFollowBtnBox'],on:{"click":function($event){$event.stopPropagation();return _vm.onClick()}}},[_vm._t("button"),_vm._v(" "),_c('SDialog',{attrs:{"visible":_vm.popupShow,"show-close":"","append-to-body":""},on:{"update:visible":function($event){_vm.popupShow=$event}}},[_c('template',{slot:"title"},[_vm._v("\n      "+_vm._s(_vm.language?.followTitleLang || _vm.langSource.SHEIN_KEY_PWA_24070)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"shop-entry__storeFollowTipsContentBox"},[_c('p',[_vm._v("\n        "+_vm._s(_vm.language?.followDescriptionLang || _vm.langSource.SHEIN_KEY_PWA_24071)+"\n      ")]),_vm._v(" "),_c('img',{attrs:{"src":`${_vm.PUBLIC_CDN}/pwa_dist/images/store/follow-store-tips-903cc3c5df.png`}}),_vm._v(" "),_c('div',{staticClass:"shop-entry__modalBtnBox"},[_c('SButton',{attrs:{"height":"36px","width":"100%","type":['primary']},on:{"click":() => (_vm.popupShow = false)}},[_vm._v("\n          "+_vm._s(_vm.language?.okLang || _vm.langSource.SHEIN_KEY_PWA_24059)+"\n        ")])],1)])],2),_vm._v(" "),_c('Transition',{attrs:{"name":"promptBox"}},[(_vm.showPromptFollow)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '2-22-20',
        data: {
          item: _vm.analyData,
        },
      }),expression:"{\n        id: '2-22-20',\n        data: {\n          item: analyData,\n        },\n      }"},{name:"tap",rawName:"v-tap",value:({
        id: '2-22-21',
        data: {
          item: _vm.analyData,
        },
      }),expression:"{\n        id: '2-22-21',\n        data: {\n          item: analyData,\n        },\n      }"}],class:{
        promptBox: true,
        noBrandShop: !_vm.isShowBranchShop
      },on:{"click":function($event){$event.stopPropagation();return _vm.onClick()}}},[_c('div',{staticClass:"content"},[(_vm.shopData.logo)?_c('div',{staticClass:"header-logo"},[_c('img',{ref:"logo",staticClass:"lazyload",attrs:{"data-src":_vm.shopData.logo.replace(/^https?:/, ''),"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.promptBoxText)}}),_vm._v(" "),_c('button',{staticClass:"follow_btn"},[_c('Icon',{attrs:{"name":"sui_icon_add_12px","size":"12px"}}),_vm._v("\n          "+_vm._s(_vm.language.followLang)+"\n        ")],1)])]):_vm._e()]),_vm._v(" "),_c('Transition',{attrs:{"name":"toastBox"}},[(_vm.showPromptToast)?_c('div',{staticClass:"toastBox toastBox1"},[_vm._v("\n      "+_vm._s(_vm.language?.followTitleLang)+"\n    ")]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }