import { getSelectInfoByScId } from 'public/src/pages/cartNew/utils/fetcher.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import { getQueryString } from '@shein/common-function'


const { langPath } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo

export const applyCoupon = (couponItem, $router, selectId = '') => {
  const { apply_for, category_id = '', coupon, scId = '', coupon_type_id, id } = couponItem
  // “全部商品”，点击则跳转到商品TAB页 / “部分商品”，则跳转到优惠券商品列表
  const offsetTop = document.querySelector(`.store-page-sticky`)?.offsetTop || 0
  const offsetHeight = document.querySelector(`.store-page-sticky`)?.offsetHeight || 0

  const coupon_type = 1
  const catIds = (category_id && category_id.join(',')) || ''
  const showTip = coupon_type_id == '2' ? 1 : 0
  switch (apply_for) {
    case '2':
      // 跳转可用商品弹窗, 去页面内请求数据
      $router.push(`${langPath}/user/coupon/add-items?code=${coupon}&typeId=${coupon_type_id}`)
      break
    case '3':
      // 跳转对应品类列表页，需要携带品类id category_id，打开新窗口
      // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
      $router.push(`${langPath}/coupon-product.html?ShowTip=${showTip}&CouponCode=${coupon.toLowerCase()}&multi_cat_ids=${catIds}&act_id=${id}&coupon_type=${coupon_type}`)
      break
    case '7':
      // 跳转选品页 scid
      // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
      $router.push(`${langPath}/coupon-sc-${scId}.html?ShowTip=1&CouponCode=${coupon.toLowerCase()}`)
      break
    case '1': {
      if (selectId) {
        getSelectInfoByScId(selectId).then(url => {
          if (url) $router.push(`${url}?comp=store-coupon&src_module=storeHomePage`)
        })
        return
      }
      window.scrollTo({
        top: offsetTop - offsetHeight,
        behavior: 'smooth',
      })
      break
    }
    default:
  }
}

export const getUseBffApi = (bffTag = 'storeHome_v1') => {
  const result = isSwitchBffApiVersion([bffTag])
  return result[bffTag]
}

export const removeURLParameter = (key) => {
  if (typeof window === 'undefined') return
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete(key)
  // 使用 replaceState 修改当前历史条目的 URL 和状态
  window.history.replaceState({}, '', currentUrl.toString());
}