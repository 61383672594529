<template>
  <div>
    <div
      v-if="couponList.length"
      class="three-coupon__package"
    >
      <div
        class="three-coupon__package_container"
        :style="{    
          background: compBg.colorType == 1 ? compBg.color : `linear-gradient(to right, ${compBg.startColor}, ${compBg.endColor})`,
          color: couponBg.textColor
        }"
      >
        <!--  待领取的优惠券-->
        <div
          v-if="couponStatus === 1"
          v-tap="getTapAnalysis({})"
          v-expose="{
            id: '2-22-53',
            data: {
              compIndex: index, 
              index: 1,          
              sceneData,
              propData,
              item_loc: index + 1,
              act_nm: couponStatus - 1, 
              couponList,           
            }
          }"
          class="coupon-package__inner-collect"
          @click="clickCollect"
        >
          <div class="coupon-package__inner-left">
            <div
              v-for="coupon in couponList"
              :key="coupon?.couponId"
              class="coupon-package"
              :style="{
                background: couponBg.bgColor,
              }"
            >
              <div class="coupon-package__desc">
                <div style="display: flex;justify-content: space-around; width: 100%">
                  <div
                    v-for="rule in coupon?.coupon_rule?.slice(0,3)"
                    :key="rule.id"
                    class="discount-wrapper"
                    :style="collectCouponWidth(coupon?.coupon_rule?.slice(0,3))"
                  >
                    <p
                      ref="couponText"
                      class="discount"
                    >
                      <!-- {{ formatBenefitsText(coupon, rule) }} -->
                      <ResizeFont>
                        <ResizeFontText
                          class="value"
                          :init-font-size="15"
                          :resize-font-min="9"
                          :resize-font-step="1"
                          :content="formatBenefitsText(coupon, rule)"
                        />
                      </ResizeFont>
                    </p>
                    <p
                      class="amount"
                      :style="{
                        color: hexToRGBA(couponBg.textColor,0.7)
                      }"
                    >
                      {{ template(rule?.min?.amountWithSymbol, compLanguage.SHEIN_KEY_PWA_27736) }}
                    </p>
                  </div>
                </div>
                <div
                  class="requirement"
                  :style="{              
                    borderTop: (couponStatus === 1) ? `1px dashed ${hexToRGBA(couponBg.textColor, 0.25)}` : 'none'
                  }"
                >
                  {{
                    isFreeshippingCoupon(coupon) ? compLanguage.SHEIN_KEY_PWA_29178 : showAllOrPart(coupon) ? compLanguage.SHEIN_KEY_PWA_27735 : compLanguage.SHEIN_KEY_PWA_27734
                  }}
                  <div
                    class="coupon-package__circle-left"
                    :style="{
                      backgroundColor: compBg.color || compBg.startColor,
                    }"
                  >
                  </div>
                  <div
                    class="coupon-package__circle-right"
                    :style="{
                      backgroundColor: compBg.color || compBg.startColor,
                    }"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div
            class="coupon-package__inner-right"
            :style="{
              backgroundImage: `url(${GB_cssRight ? couponCollect?.arBgRightImage?.src : couponCollect?.bgRightImage?.src})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
            }"
          >
            <div
              class="collect"
              :style="{
                color: buttonTextColor,
                background: `${ couponCollect?.buttonBgColor?.colorType == 1 ? couponCollect?.buttonBgColor?.color : `linear-gradient(to right, ${couponCollect?.buttonBgColor?.startColor}, ${couponCollect?.buttonBgColor?.endColor})`}`,
              }"
            >
              {{ compLanguage.SHEIN_KEY_PWA_27737 }}
            </div>
          </div>
        </div>
        <!--      已登陆已领取待使用的优惠券 以及兜底券-->
        <div
          v-if="couponStatus === 2 || couponStatus === 3"
          class="coupon-package__inner-use"
          :style="{      
            color: couponBg.textColor
          }"
        >
          <div
            v-for="(coupon,index) in couponList"
            :key="coupon.couponId || coupon.id"
            v-expose="{
              id: '2-22-53',
              data: {
                compIndex: index, 
                index,          
                sceneData,
                propData,
                item_loc: index + 1,
                act_nm: couponStatus - 1,           
                couponList: [coupon],             
              }
            }"
            v-tap="getTapAnalysis({ coupon, index })"
            :style="{
              background: couponBg?.bgColor,     
            }"
            class="coupon-package__item"
            @click="clickCoupon(coupon)"
          >
            <div
              class="coupon-package__inner-left"
              :style="{
                borderRight: (couponStatus === 2 || couponStatus === 3) ? `1px dashed ${hexToRGBA(couponBg.textColor, 0.25)}` : 'none'
              }"
            >
              <div class="coupon-package__desc">
                <div style="display: flex;justify-content: space-around;width: 100%">
                  <div
                    v-for="rule in coupon?.coupon_rule?.slice(0,3)"
                    :key="rule.id || rule.coupon_range_id"
                    class="discount-wrapper"
                    :style="useCouponWidth(coupon?.coupon_rule?.slice(0,3))"
                  >
                    <p
                      ref="couponText"
                      class="discount"
                    >
                      <!-- {{ formatBenefitsText(coupon, rule) }} -->
                      <ResizeFont>
                        <ResizeFontText
                          class="value"
                          :init-font-size="15"
                          :resize-font-min="9"
                          :resize-font-step="1"
                          :content="formatBenefitsText(coupon, rule)"
                        />
                      </ResizeFont>
                    </p>
                    <p
                      class="amount"
                      :style="{
                        color: hexToRGBA(couponBg.textColor,0.7)
                      }"
                    >
                      {{ template(rule?.min?.amountWithSymbol, compLanguage.SHEIN_KEY_PWA_27736) }}
                    </p>
                  </div>
                </div>
                <div
                  class="requirement"
                  :style="{
                    backgroundColor: `${hexToRGBA(couponBg.textColor, 0.05)}`,
                  }"
                >
                  {{
                    isFreeshippingCoupon(coupon) ? compLanguage.SHEIN_KEY_PWA_29178 : showAllOrPart(coupon) ? compLanguage.SHEIN_KEY_PWA_27735 : compLanguage.SHEIN_KEY_PWA_27734
                  }}
                </div>
              </div>
              
              <div
                class="coupon-package__circle-top"
                :style="{
                  backgroundColor: compBg.color || compBg.startColor,
                }"
              >
              </div>
              <div
                class="coupon-package__circle-bottom"
                :style="{
                  backgroundColor: compBg.color || compBg.startColor,
                }"
              >
              </div>
            </div>
            <div class="coupon-package__inner-right">
              <template v-if="!(couponStatus === 3)">
                <div
                  class="btn-use"
                  :style="{
                    color: buttonTextColor,
                    background: `${ couponUse?.buttonBgColor?.colorType == 1 ? couponUse?.buttonBgColor?.color : `linear-gradient(to right, ${couponUse?.buttonBgColor?.startColor}, ${couponUse?.buttonBgColor?.endColor})`}`,
                  }"
                >
                  {{ compLanguage.SHEIN_KEY_PWA_28298 }}
                </div>
                <div
                  v-if="showExpireText(coupon)"
                  class="expire"
                >
                  {{ compLanguage.SHEIN_KEY_PWA_27838 }}
                </div>
              </template>
              <template v-else>
                <p
                  class="code"
                  :style="{
                    color: couponBg.textColor
                  }"
                >
                  {{ compLanguage.SHEIN_KEY_PWA_27207 }}
                </p>
                <div
                  class="code-value"
                  :style="{
                    color: codeTextColor,
                    backgroundColor: couponBg.textColor,
                  }"
                >
                  {{ coupon.couponCode }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      
      <!--    <button @click="bindCouponPackage">-->
      <!--      绑定-->
      <!--    </button>-->
      <!--    &lt;!&ndash;    <button @click="open">&ndash;&gt;-->
      <!--    &lt;!&ndash;      打开&ndash;&gt;-->
      <!--    &lt;!&ndash;    </button>&ndash;&gt;-->
      <!--    <button @click="openCouponDialog">-->
      <!--      打开凑单-->
      <!--    </button>-->
    </div>
    <!--    <div-->
    <!--      v-if="showPlaceHolder"-->
    <!--      class="three-coupon__package-placeholder"-->
    <!--    >-->
    <!--    </div>-->
    <ClientOnly>
      <coupon-dialog
        :language="compLanguage"
        scene="home"
        :show="showCouponDialog"
        :coupon-list="hasReceived ? [] : couponList"
        @onCouponClose="()=>{ showCouponDialog = false }"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { getCouponPackageInfo } from 'public/src/services/api/ccc.js'
import mixin from '../mixins/mixin'
import { template } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { Toast } from '@shein/sui-mobile'
import schttp from 'public/src/services/schttp'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import CouponDialog from 'public/src/pages/common/coupon_package_v2/CouponResult.vue'
import AddOnItem from 'public/src/pages/common/addOnItem/index.js'
import { cccxEventBus, hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { getServerTime } from 'public/src/pages/components/ccc/common/utils.js'
import ResizeFont from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFont.vue'
import ResizeFontText from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFontText.vue'
import ClientOnly from 'vue-client-only'

const { GB_cssRight, language } = gbCommonInfo

export default {
  name: 'CouponPackage',
  components: {
    CouponDialog,
    ResizeFont,
    ResizeFontText,
    ClientOnly
  },
  mixins: [mixin],
  props: {
    ...commonProps
  },
  data() {
    const { couponId = '', couponList = [], couponStatus = 0 } = this.propData.couponPackage ?? {}
    return {
      GB_cssRight,
      gbLanguage: language,
      isDouDi: false,
      bindStatus: false,
      couponStatus, // 券包状态： 1待领取 2已领取 3兜底券
      showCouponDialog: false,
      // isCouponDialogInited: false, // 是否初始化三档券弹框 
      couponId,
      couponCodes: couponList?.map(coupon => coupon.couponCode) ?? [],
      couponList,
      abtInfo: {},
      jumpType: 0,
      hasReceived: false,
      serverTime: '',
      showPlaceHolder: true
    }
  },
  computed: {
    items() {
      return this.propData?.props?.items ?? []
    },
    compLanguage() {
      return this.metaData?.language
    },
    colorTemplateConfig() {
      return this.metaData?.colorTemplateConfig ?? {}
    },
    compBg() {
      return this.colorTemplateConfig?.componentBgColor ?? {}
    },
    couponBg() {
      return this.colorTemplateConfig?.coupon ?? {}
    },
    couponCollect() {
      return this.colorTemplateConfig?.couponCollect ?? {}
    },
    couponUse() {
      return this.colorTemplateConfig?.couponUse ?? {}
    },
    buttonTextColor() {
      return this.colorTemplateConfig?.buttonTextColor
    },
    codeTextColor() {
      return this.colorTemplateConfig?.codeTextColor
    },
  },
  activated() {
    // 防止误触发
    if (!this.isMounted) return
    this.getCouponInfo()
  },
  async mounted() {
    this.isMounted = false
    appEventCenter.$on('homeCccxCouponUpdate', () => {
      this.getCouponInfo()
    })
    cccxEventBus?.on?.('couponPackage-bindCouponSuccess', () => {
      this.getCouponInfo()
    })
    const [abtInfo, ] = await Promise.all([getUserAbtData(), this.propData.isFirstPage ? null : this.getCouponInfo()])
    this.abtInfo = abtInfo
    this.jumpType = this.abtInfo?.CouponBlock?.p?.CouponUseJumpType == 1 ? 1 : 0
    this.isMounted = true
    this.serverTime = await getServerTime()
  },
  methods: {
    collectCouponWidth(rule) {
      let ruleCount = 0
      this.couponList?.forEach(coupon => {
        coupon?.coupon_rule?.slice(0, 3)?.forEach(() => {
          ruleCount += 1
        })
      })
      if (ruleCount >= 4 && rule.length >= 2) {
        return { width: `calc(42vw - 2rem)` }
      }
      
      if (ruleCount >= 4) {
        return { width: `calc(50vw - 2rem)` }
      }
      if (this.couponList.length === 1 && rule.length === 3) {
        return { width: `calc(42vw)` }
      }
      if (this.couponList.length === 1 && rule.length === 1) {
        return { width: `calc(100vw - 2rem)` }
      }
      if (this.couponList.length === 1 && rule.length === 2) {
        return { width: `calc(57vw - 1.85rem)` }
      }
      
      if (this.couponList.length >= 3 && rule.length === 1) {
        return { width: `calc(45vw - 1.8rem)` }
      }
      
      if (this.couponList.length === 2 && rule.length === 1) {
        return { width: `calc(47vw - 1rem)` }
      }
      return { width: `calc(45vw - 1.8rem)` }
    },
    useCouponWidth(rule) {
      let ruleCount = 0
      this.couponList?.forEach(coupon => {
        coupon?.coupon_rule?.slice(0, 3).forEach(() => {
          ruleCount += 1
        })
      })
      if (ruleCount >= 4 && rule.length >= 2) {
        return { width: `calc(42vw - 2rem)` }
      }
      
      if (ruleCount >= 4) {
        return { width: `calc(50vw - 2rem)` }
      }
      if (this.couponList.length === 1 && rule.length === 3) {
        return { width: `calc(45vw - 1.92rem)` }
      }
      if (this.couponList.length === 1 && rule.length === 1) {
        return { width: `calc(100vw - 2.25rem)` }
      }
      if (this.couponList.length === 1 && rule.length === 2) {
        return { width: `calc(57vw - 1.85rem)` }
      }
      
      if (this.couponList.length >= 3 && rule.length === 1) {
        return { width: `calc(45vw - 1.8rem)` }
      }
      
      if (this.couponList.length === 2 && rule.length === 1) {
        return { width: `calc(47vw - 1.8rem)` }
      }
      return { width: `calc(45vw - 1.8rem)` }
    },
    hexToRGBA(color, opacity){
      if (!/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(color)){
        return ''
      }
      return hexToRGBA(color, opacity)
    },
    template,
    getJumpUrl(coupon) {
      let url
      if (this.couponStatus === 3) { // 兜底券，只走CCC跳转
        const curCoupon = this.items.find(item => item.code?.toUpperCase() === (coupon.coupon || coupon.couponCode)?.toUpperCase())
        url = this.cccLink.getFullLink({
          item: curCoupon,
          index: this.index
        })
        return {
          url,
          hrefType: curCoupon?.hrefType
        }
      }
      if (+this.jumpType === 1) {
        return {}
      } else {
        const url = this.cccLink.getFullLink({
          item: {
            ...this.metaData
          }
        })
        return {
          url,
          hrefType: this.metaData?.hrefType
        }
      }
    },
    clickCoupon(coupon) {
      const { url, hrefType } = this.getJumpUrl(coupon)
      if (url) {
        this.jumpLink({ url, hrefType })
      } else {
        this.openCouponDialog(coupon)
      }
    },
    formatBenefitsText(coupon, rule) {
      // 免邮券
      if ([5, 6].includes(+coupon?.apply_for) || ([9].includes(+coupon?.apply_for) && [0].includes(+coupon?.shipping_discount_type))) { // 免邮
        return this.compLanguage?.SHEIN_KEY_PWA_27784
      }
      // 非全免邮
      if (([9].includes(+coupon?.apply_for) && [1].includes(+coupon?.shipping_discount_type))) {
        return this.template(rule?.value?.amountWithSymbol, this.compLanguage.SHEIN_KEY_PWA_27781) // 满减
      }
      if ([2, 5].includes(+coupon.coupon_type_id)) {
        return this.template(Math.ceil(rule?.value?.amount), this.compLanguage.SHEIN_KEY_PWA_27783) // 折扣券
      }
      return this.template(rule?.value?.amountWithSymbol, this.compLanguage.SHEIN_KEY_PWA_27781) // 满减
    },
    openCouponDialog(coupon) {
      AddOnItem.open({
        props: {
          type: 'coupon',
          coupon: coupon?.couponCode, // **券码** 必传！！！
          queryInfo: {
            addOnType: 5, // 问产品拿
            sceneId: 152, // 推荐场景id
            // goodsIds: [this.detail?.goods_id],
            // cateIds: [this.detail?.cat_id]
          },
          saInfo: {
            // 主要用于埋点
            activity_from: 'homePage',
            state: 'homepage_banner'
          }
        },
        on: {
          close() {
            // if (self.viewPromotionIsQuickAdd) self.isCloseAddOnItem = true
          }
        }
      })
    },
    async getCouponInfo() {
      const [ couponData ] = await Promise.all([getCouponPackageInfo({
        codes: this.items.map(item => item.code) || []
      })])
      const { couponList = [], couponStatus, couponId = '' } = couponData
      this.couponId = couponId
      this.couponCodes = couponList?.map(coupon => coupon.couponCode)
      this.couponStatus = couponStatus
      // 滤膨胀券及会费券（ applyFor=10）
      this.couponList = couponList
      this.showPlaceHolder = false
    },
    onCouponClose() {
      this.showCouponDialog = false
    },
    async bindCouponPackage() {
      try {
        const { code, info } = await schttp({
          method: 'POST',
          url: '/api/coupon/bindCouponPackage/update',
          data: {
            couponId: this.couponId,
            coupon_codes: this.couponCodes // 券码
          },
        })
        
        const successList = info?.bindResult?.successList || []
        if (code == '0' && successList?.length) {
          this.couponList = this.couponList.filter(coupon => {
            return successList?.findIndex(_coupon => _coupon.couponCode === coupon.couponCode) !== -1
          })
          this.bindStatus = true
          this.couponStatus = 2
          this.showCouponDialog = true
          this.getCouponInfo()
          // TODO 绑定成功埋点
        } else if (code == '501402') {
          // 已领取过了
          this.hasReceived = true
          this.showCouponDialog = true
          this.getCouponInfo()
        } else {
          Toast(this.compLanguage?.SHEIN_KEY_PWA_28872 || 'fail') // 绑定失败
          // TODO 绑定失败埋点
        }
      } catch (error) {
        Toast(this.compLanguage?.SHEIN_KEY_PWA_29132 || 'network error') // 绑定失败
        // TODO 异常埋点
      }
    },
    getTapAnalysis({ coupon, index }) {
      const codeList = this.couponList.map(coupon => coupon.couponCode)
      const { url = '' } = this.getJumpUrl(coupon)
      return {
        id: '2-22-54',
        data: {
          compIndex: this.index,
          index,
          item: coupon,
          sceneData: this.sceneData,
          propData: this.propData,
          item_loc: this.couponStatus == 1 ? 1 : index,
          act_nm: this.couponStatus - 1,
          new_coupon_code: this.couponStatus == 1 ? codeList?.join(',') : coupon.couponCode,
          use_jump_type: this.jumpType,
          use_jump_link: url,
        }
      }
    },
    clickCollect() {
      if (!isLogin()) {
        SHEIN_LOGIN.show({
          show: true,
          from: 'other',
          cb: this.getCouponInfo,
        })
      } else {
        this.bindCouponPackage()
      }
    },
    // 显示全部可用or部分可用
    showAllOrPart(item) {
      return (item.coupon_dimension == '1' && item.apply_for == '1' && item.filter_rule == 0 && [1, 5].includes(+item.coupon_type_id)) || (item.coupon_dimension == '1' && [5, 6].includes(+item.apply_for) && item.shippingApplyFor == 1 && item.filter_rule == 0)
    },
    isFreeshippingCoupon(item) {
      return [5, 6].includes(+item?.apply_for) || ([9].includes(+item?.apply_for) && [0, 1].includes(+item?.shipping_discount_type))
    },
    showExpireText(coupon) {
      const seconds = 72 * 3600
      let curTimeStamp = Math.floor(this.serverTime / 1000)
      return (+coupon.end_date - curTimeStamp <= seconds)
    }
  }
}
</script>

<style lang="less" scoped>
.three-coupon__package-placeholder {
  height: 1.5rem;
  background-color: #e5e5e5;
  margin-bottom: 16/75rem;
}

.three-coupon__package {
  margin: 8/75rem;
  //padding-right: 16/75rem;
  
  &_container {
    display: flex;
    width: 100%;
    //height: 100/75rem;
    border-radius: 2px;
    background: #FFEDED;
    //padding: 8/75rem;
    // padding-right: 0;
    
    // 待领取
    .coupon-package__inner-collect {
      height: 100%;
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: space-between;
      border-radius: 2px;
      
      .coupon-package__inner-left {
        margin: 8/75rem;
        margin-right: 0;
        //margin-right: 16/75rem;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        //margin-right: 8px;
        position: relative;
        width: calc(100% - 2rem);
        // min-width: fit-content;
        // border-right: 1px dashed #FFEDED;
        
        &::-webkit-scrollbar {
          display: none; /* 隐藏滚动条（适用于Webkit浏览器） */
        }
        
        .coupon-package {
          border-radius: 2px;
          background-color: #fff;
          margin-right: 4px;
          //min-width: 45%;
          //min-width: fit-content;
          width: calc(100% - 5px);
          
          &:last-child {
            // margin-right: 0;
          }
        }
        
        .coupon-package__desc {
          //padding: 0 10px;
          position: relative;
          display: flex;
          height: 100%;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          
          &:last-child {
            // padding-right: 0;
          }
          
          .discount-wrapper {
            //flex: 1;
            //width: calc((100vw - 2.4rem) / 3);
            text-align: center;
            padding: 0 8/75rem;
            
            //&:nth-child(2) {
            //  padding: 0;
            //}
            
            &:last-child {
              margin-right: 0;
            }
          }
          
          .discount {
            padding-top: 8/75rem;
            .text-overflow();
            word-wrap: break-word;
            // color: #FA6338;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            //line-height: normal;
            height: 27px;
            line-height: 22px;
          }
          
          .amount {
            padding: 2/75rem;
            padding-bottom: 6/75rem;
            // color: rgba(250, 99, 56, 0.70);
            text-align: center;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          
          .requirement {
            padding: 0 12/75rem;
            margin-bottom: 4/75rem;
            position: relative;
            //.text-overflow();
            width: 100%;
            text-align: center;
            // color: #FA6338;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            height: 14px;
            // border-top: 1px dashed #FFEDED;
            .coupon-package__circle-left, .coupon-package__circle-right {
              position: absolute;
              width: 14/75rem;
              height: 14/75rem;
              // background-color: #FFEDED; /* 背景颜色，这里用红色举例 */
              border-radius: 50%
            }
            
            .coupon-package__circle-left {
              left: -4px;
              bottom: 10px;
            }
            
            .coupon-package__circle-right {
              right: -4px;
              bottom: 10px;
            }
          }
        }
        
      }
      
      .coupon-package__inner-right {
        flex-shrink: 0;
        position: relative;
        width: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        //background-color: #fff;
        
        .collect {
          position: absolute;
          left: calc(50% + 2px);
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          font-size: 11px;
          text-align: center;
          width: 125/75rem;
          height: 36/75rem;
          line-height: 36/75rem;
          font-weight: 500;
          //flex-shrink: 0;
          border-radius: 90px;
          color: #fff;
          background: linear-gradient(90deg, #FF9A43 13.15%, #F65130 99.91%);
          box-shadow: 0 2px 3px 0 rgba(255, 255, 255, 0.40) inset, 0 2px 2px 0 rgba(178, 108, 71, 0.20);
        }
        
      }
    }
    
    // 待使用
    .coupon-package__inner-use {
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 8/75rem;
      
      &::-webkit-scrollbar {
        display: none; /* 隐藏滚动条（适用于Webkit浏览器） */
      }
      
      display: flex;
      //flex: 1;
      justify-content: space-between;
      height: 100%;
      border-radius: 2px;
      
      .coupon-package__item {
        border-radius: 2px;
        background-color: #fff;
        position: relative;
        width: 100%;
        min-width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        //
        &:last-child {
          margin-right: 0;
        }
      }
      
      .coupon-package__inner-left {
        position: relative;
        width: calc(100% - 2rem);
        min-width: fit-content;
        height: 100%;
        // border-right: 1px dashed #FFEDED;
        
        .coupon-package__desc {
          // padding: 0 10px;
          display: flex;
          height: 100%;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          
          .discount-wrapper {
            flex: 1;
            //max-width: calc((100vw - 2rem - 18px) / 3);
            text-align: center;
            padding: 0 4px;
            
            &:nth-child(2) {
              padding: 0 4px;
            }
            
            &:last-child {
              margin-right: 0;
            }
          }
          
          .discount {
            padding-top: 8/75rem;
            .text-overflow();
            // color: #FA6338;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            height: 27px;
            line-height: 22px;
          }
          
          .amount {
            padding-top: 2/75rem;
            padding-bottom: 6/75rem;
            // color: rgba(250, 99, 56, 0.70);
            text-align: center;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          
          .requirement {
            padding: 0 12/75rem;
            margin-bottom: 4/75rem;
            height: 14px;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
        }
        
        .coupon-package__circle-top, .coupon-package__circle-bottom {
          position: absolute;
          width: 14/75rem;
          height: 14/75rem;
          // background-color: #FFEDED; /* 背景颜色，这里用红色举例 */
          border-radius: 50%
        }
        
        .coupon-package__circle-top {
          right: -4px;
          top: -3px;
        }
        
        .coupon-package__circle-bottom {
          right: -4px;
          bottom: -3px;
        }
      }
      
      .coupon-package__inner-right {
        width: 1.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .btn-use {
          .text-overflow();
          padding: 0 5px;
          margin-top: 2px;
          text-align: center;
          width: 120/75rem;
          height: 36/75rem;
          //flex-shrink: 0;
          border-radius: 90px;
          font-weight: 500;
          color: #fff;
        }
        
        .code-value {
          .line-camp();
          word-wrap: break-word;
          padding: 0 0.18rem;
          text-align: center;
          width: 1.6rem;
          max-height: 0.96rem;
          line-height: 1.4em;
          //line-height: 0.32rem;
          //flex-shrink: 0;
          border-radius: 90px;
          font-weight: 700;
        }
        
        .expire, .code {
          text-align: center;
          //color: #FA6338;
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          //line-height: 80%; /* 6.4px */
        }
        
        .expire {
          margin-top: 2px;
        }
      }
    }
    
  }
}
</style>
