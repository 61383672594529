const compTypes = {
  IMAGE_COMPONENT: 'StaticImageCardController', // 静态图组件（单图、双图、三图、四图）
  TEXT_EDIT: 'TextEdit', // 图文组件
  CODE_IMAGE_COMPONENT: 'StaticImage', // code合图组件
  VERTICAL_ITEMS: 'VerticalItems', // 纵向商品列表
  HORIZONTAL_ITEMS: 'HorizontalItems', // 横向商品列表
  ABOVE_PICTURE_ITEMS: 'AbovePictureItems', // 上图下商品组件
  PRODUCT_LEFT_COMPONENT: 'LeftPictureItems',  // 左图右商品组件
  DAILYNEW_ITEMS: 'DailyNewItems',  // dailynew商品组件
  HORIZONTAL_COUPON: 'HorizontalCoupon',  // 通用横向券
  VERTICAL_COUPON: 'VerticalCoupon', // 通用纵向券
  STORE_HORIZONTAL_COUPON: 'StoreHorizontalCoupon', // 店铺横向券
  STORE_VERTICAL_COUPON: 'StoreVerticalCoupon',  // 店铺纵向券
  PLATFORM_HORIZONTAL_COUPON: 'StoreHorizontalCoupon', // 平台横向优惠券（店铺）
  PLATFORM_VERTICAL_COUPON: 'StoreVerticalCoupon',  // 平台纵向优惠券 （店铺）
  IMAGE_CAROUSEL_COMPONENT: 'SideslipImage', //  滑动多图组件(轮播图、横滑2.5、横滑2,3,4,5)
  FLASH_SALE: 'FlashSale', // 闪购
  FLASH_SALE_BANNER: 'FlashSaleBanner', // 闪购banner
  LEFT_FLASH_SALE: 'LeftFlashSale', // 左图闪购
  STORE_INFO_COMPONENT: 'ShopBanner', // 店招
  SHOPACTIVITY_COMPONENT: 'ShopActivity', // 店铺促销活动组件
  CATEGORY_ITEMS_DYNAMIC: 'CategoryItemsDynamic', // 商品列表组件
  COUNTDOWN_COMPONENT: 'Countdown', // 倒计时
  CALENDAR: 'Calendar', // 日历
  HORIZONTAL_ANCHOR: 'HorizontalAnchor', // 锚点组件（文案锚点、图片锚点）
  HORIZONTAL_ANCHOR_IMAGE: 'HorizontalAnchorImage', // 锚点组件（文案锚点、图片锚点）
  RANKING_ENTRANCE: 'RankingEntrance', // 排行榜入口
  RANKING_LIST2_COMPONENT: 'RankingEntranceList2', // 排行榜2卡入口
  RANKING_LIST: 'RankingList', // 排行榜
  RANKING_ENTRANCE_DYNAMIC: 'RankingEntranceList2Home', // 动态排行榜入口
  HOT_RANKING_LIST: 'HotRankingList', // 新排行榜落地页
  VIDEO_COMPONENT: 'Video',  // 视频组件
  POINTS_COMPONENT: 'Integral',  // 领积分
  SUBSCRIBE_COMPONENT: 'Subscribe', // 订阅组件
  SURVEY_COMPONENT: 'Survey', // 意见收集组件
  PRODUCT_RECOMMEND_FLOW: 'RecommendFlow', // 推荐商品
  PRODUCT_RECOMMEND_FOR_SHOP: 'RecommendFlow', // 店铺专属推荐商品组件
  PRODUCT_RECOMMEND_THREE_LIST: 'RecommendFlow', // 三列推荐商品
  PRODUCT_RECOMMEND_HORIZONTAL: 'RecommendFlow', // 横滑推荐商品
  PK_COMPONENT: 'PK', // PK投票组件,
  CLUB_PROMOTION: 'Prime', // 付费会员组件
  FREE_SHIPPING_COMPONENT: 'FreeShippingInfo',   // 免邮凑单组件
  RANKING_ENTRANCE_RECOMMEND: 'RankingEntranceRecommend', // 排行榜推荐组件
  BRAND_RECOMMEND_COMPONENT: 'BrandRecommend', // 品牌推荐
  LOOKBOOK: 'LookBook', // 系列搭配
  SHOPLIST_COMPONENT: 'StoreCardList', // 店铺卡片列表组件
  SHOPLIST_COMPONENT_HORIZONTAL: 'StoreHorizontalCardList', // 店铺横向卡片列表组件
  MULTI_SHOP_COMPONENT: 'StoreMultiSlide', // 多店铺横滑组件
  MULTI_SHOP_ONEITEM: 'StoreMultiSlideOne', //多店铺一店一品组件
  MULTI_SHOP_ITEMCARD: 'StoreMultiSlideCard', // 多店铺圆角卡片组件
  SHOPCARD_COMPONENT: 'StoreSingleCard', // 店铺单卡片组件
  CATEGORY_RECOMMEND_DYNAMIC: 'CategoryRecommendDynamic', // 新品类热度推荐
  HALF_COMPONENT_CONTAINER: 'HalfComponentContainer', // 二分之一组件容器
  CATEGORY_BRAND_ITEM_DYNAMIC: 'CategoryBrandItem', // 品类品牌单品
  SINGLE_PRODUCT_DYNAMIC: 'SingleProduct', // 单品
  ACTIVITY_BANNER: 'ActivityBanner', // 活动banner组件
  SINGLE_ACTIVITY_BANNER: 'StorePageSingleActivityBanner', // 单活动banner
  MULTI_ACTIVITIES_BANNER: 'StorePageSingleActivityBanner', // 多活动banner
  BRAND_DISPLAY_COMPONENT: 'BrandDisplay', // 品牌展示组件
  CATEGORY_DISPLAY_COMPONENT: 'CategoryDisplay', // 品类展示组件
  COLLOCATION_DISPLAY_COMPONENT: 'CollocationDisplay', // 搭配展示组件
  FALL_COMPONENT: 'FallList', // 直降列表
  SAVE_COMPONENT: 'SaveList', // 节省列表
  BRAND_LIST: 'BrandPavilionSideSlip', // 品牌 - 横滑组件
  BRAND_WALL: 'BrandWall', // 品牌墙
  BRAND_BRANDLIST: 'AZBrandList', // a~z品牌列表
  BRAND_ITEMS_LIST: 'BrandItemsList', // 品牌商品流列表
  HEADER_COMPONENT: 'BrandHeader', // 品牌馆页头组件
  BRAND_NEWUSERS: 'BrandNewusers', // 品牌 - 新人活动入口
  SHEIN_X: 'SheinXList', // 设计师组件
  SHEIN_X_VOTE: 'SheinXVote', // 设计师投票组件
  SEARCH_COMPONENT: 'UniversalSearch', // 通用搜索组件
  STORE_DAILYNEW_ITEMS: 'StoreDailynew', // 店铺dailynew商品列表组件
  STORE_LOOKBOOK: 'StoreLookbook', // 店铺lookbook组件
  STORE_CATEGORY_RECOMMEND: 'StoreCategoryRecommend', // 品类热度推荐(店铺)
  CART_ICON: 'CartIcon', // 购物车悬浮球
  STORE_ITEM_IMAGE_FOUR_COLS_CAROUSEL: 'StoreSideslipImage', //  滑动多图组件(轮播图、横滑2.5、横滑2,3,4,5)
  STORE_ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE: 'StoreSideslipImage', //  滑动多图组件(轮播图、横滑2.5、横滑2,3,4,5)
  DRAW_SPIN: 'DrawSpin', // 抽奖 - 转盘抽奖组件
  NEWS_SECTION: 'NewsSection', // 新闻板块组件
  STORE_FLASH_SALE: 'StoreFlashSale', // 店铺闪购组件
  STORE_DISCOUNT_PRODUCTS_RECOMMEND: 'StoreDiscountProductsRecommend', // 店铺折扣商品组件
  NEW_USER_HOME: 'NewUserHome', // 新客组件
  NEWUSER_PACKAGE: 'NewUserPackage', // 推客券包组件
  STORE_FOLLOW: 'StoreFollow', // 店铺关注引导组件

  // ===== 以下只写动态组件 =====
  MULTI_ITEMS_CAROUSEL_TABS_DYNAMIC: 'SideslipImageTabDynamic', // 动态组件 - 横滑4图 - 多TAB
  MULTI_ITEMS_CAROUSEL_IND_DYNAMIC: 'SideslipImageTabDynamic', // 动态组件 - 横滑4图 - 整屏滑动
  MULTI_ITEMS_CAROUSEL_CON_DYNAMIC: 'SideslipImageMarqueeDynamic', // 动态组件 - 横滑4图 - 无极横滑
  FLASH_SALE_DYNAMIC: 'FlashSaleDynamic', // 动态组件 - 闪购
  LEFT_FLASH_SALE_DYNAMIC: 'LeftFlashSaleDynamic', // 动态组件 - 左图闪购 
  HORIZONTAL_ITEMS_DYNAMIC: 'HorizontalItemsDynamic', // 动态组件 - 横滑商品
  MULTI_ITEMS_CAROUSEL_CHANNEL_ENTRANCE_DYNAMIC: 'HorizontalSlideChanelDynamic', // 动态组件 - 横滑3.5图 - 无极横滑
  MULTI_CAROUSEL_CHANNEL_ENTRANCE_NEW: 'HorizontalSlideChanelDynamicNew', // 动态组件 - 横滑二期4.1 - 无极横滑
  CODE_DYNAMIC: 'CodeDynamic', // 优惠券（单券）组件
  DOUBLE_CODES_DYNAMIC: 'DoubleCodesDynamic', // 优惠券（双券）组件
  // 单图 双图 三图
  ONE_IMAGE_DYNAMIC: 'DynamicImage',
  THREE_IMAGES_DYNAMIC: 'DynamicImage',
  // 横滑动3.5图
  MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC: 'SideslipImageDynamic',
  // 轮播图
  IMAGE_CAROUSEL_DYNAMIC: 'SideslipImageDynamic', //  滑动多图组件(轮播图、横滑2.5、横滑2,3,4,5) 
  // 沉浸式组件
  IMMERSIVE_BANNER: 'ImmersiveBanner',
  // 三档券包
  COUPON_PACKAGE: 'CouponPackage',
  PRODUCT_ITEMS_COMPONENT: 'ProductItems', // 横滑自选商品组件
  // 不贵组件
  SUPER_DEALS: 'SuperDeals',
  // 品牌品类轮播组件
  CATEGORY_ITEMS: 'CategorySlide',
  // 历史最低价组件
  LOWEST_PRICE: 'LowestPrice',
  STORE_HORIZONTAL_ITEMS: 'StoreHorizontalItems', // 店铺横向列表
  STORE_VERTICAL_ITEMS: 'StoreVerticalItems', // 店铺纵向列表
  TRENDS_ITEMS: 'trendItems', // 趋势组件
  TREND_STORE_RECOMMEND: 'TrendStoreRecommend',
  // 店铺视频
  STORE_VIDEO: 'StoreVideo',
  MULTI_LINE_CONTAINER_COMPONENT: 'MultiLine',
  // 店铺装修热销榜
  STORE_HOT_PRODUCTS_RECOMMEND: 'StoreRankHotSale',
  // 店铺热搜词组件
  STORE_HOT_SEARCHTERM_RECOMMEND: 'StoreHotSearchtermRecommend', 
  // 店铺装修上新
  STORE_NEW_ARRIVALS_RECOMMEND: 'StoreNewArrivalList',
}

export const CATE_TYPE = {
  TWO_CATEGORY_RECOMMEND_COMPONENT: 2,     // 一行2图
  FOUR_CATEGORY_RECOMMEND_COMPONENT: 4,    // 一行4图
  FIVE_CATEGORY_RECOMMEND_COMPONENT: 5,    // 一行5图
}

export const compMapping = ({ styleType = '', componentKey = '' }) => {
  return compTypes[styleType] || compTypes[componentKey] || ''
}
