<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div class="shop-banner__data-labels">
    <!-- trends label -->
    <TrendLabel
      v-if="!!trendsLabelInfo.status"
      :trends-label-info="trendsLabelInfo"
    />
    <!-- brand label -->
    <BrandLabel
      v-if="isBrandStore"
      :constants-data="constantsData"
      :store-banner-lang="storeBannerLang"
    />
    <!-- 优选卖家 label -->
    <PreferredSellerLabel
      v-if="isPreferredSeller"
      :constants-data="constantsData"
      :store-banner-lang="storeBannerLang"
    />
    <!--本地卖家-->
    <LocalSellerLabel
      v-if="formattedData?.localSellerLabel"
      :label-text="formattedData.localSellerLabel"
    />
  </div>
</template>

<script setup name="StoreLabels">
import { computed } from 'vue'
import TrendLabel from './data-labels/TrendLabel.vue'
import BrandLabel from './data-labels/BrandLabel.vue'
import PreferredSellerLabel from './data-labels/PreferredSellerLabel.vue'
import LocalSellerLabel from './data-labels/LocalSellerLabel.vue'

const props = defineProps({
  constantsData: {
    type: Object,
    default: () => ({}),
  },
  storeBannerLang: {
    type: Object,
    default: () => ({}),
  },
  shopData: {
    type: Object,
    default: () => ({}),
  },
  formattedData: {
    type: Object,
    default: () => ({}),
  },
})
const isBrandStore = computed(() => props.shopData?.isBrandStore)
const trendsLabelInfo = computed(() => props.shopData?.trendsLabelInfo || {})
const isPreferredSeller = computed(() => props.formattedData?.isPreferredSeller)
</script>

<style lang="less" scoped>
.shop-banner__data-labels {
  & > :not(:first-child) {
    margin-left: 4/37.5rem;
  }
}

</style>
